/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import { Box, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { read, utils } from "xlsx";
import * as Iconv from 'iconv-lite';
import {
  apiEndpoint
} from "../../../constants/appConstant";
import {
  ErrMsgObject,
  WarningMsgObject
} from "../../../interfaces/commonInterface";
import { updatePineDataActions } from "../../../store/uploadPineSlice";
import '../../../styles/index.css';
import {
  getClearIdFromDB,
  structuredJson2,
} from "../../../utility/commonFunction";
import { triggerCustomEventsGTM } from "../../../utility/ga";
import CustomDivider from "../atom/CustomDivider";
import IdentifiedFilesText from "../atom/Text/IdefntifiedFilesText";
import PageText from "../atom/Text/Page2Of3";
import SubTexts from "../atom/Text/Subtexts";
import UploadIcon from "../atom/UploadIcon";
import { ALREADY_EXIST_REF_ID_ERR_TITLE, DUPLICATE_FILENAME_ERR_TITLE, DUPLICATE_FILE_ERR_TITLE, DUPLICATE_REF_ID_ERR_TITLE, FILE_TYPE_ERR_TITLE, FILE_UPLOAD_NAME_CHANGE_TITLE, HTTPS_LOCATION_NOTFOUND_ERR_TITLE, INVALID_FILE_UPDATE_ERR_TITLE, INVALID_PLACEHOLDER_ERR_TITLE, METADATA_FILE_ERR_TITLE, METADATA_UPLOAD_ERR_TITLE, MISSING_EXTENSION_ERR_TITLE, MISSING_NON_REQ_FIELDS_WARN_TITLE, REQUIRED_FIELD_ERR_TITLE, S3_LOCATION_NOTFOUND_ERR_TITLE, LOCAL_EXCEEDED_ROWS, LOCAL_EXCEED_LIMIT } from "../constants/AddSingleFileConstants";
import ButtonsWithGrid from "../molecule/ButtonsWithGrid";
import CustomToggle from "../molecule/CustomToggle";
import CustomToogleWithTextField from "../molecule/CustomToogleWithTextField";
import CustomizedTables from "../molecule/CustomizedTables";
import DragandDropWithErrorMsg from "../molecule/DragandDropWithErrorMsg";
import FormControlGrid from "../molecule/FormControl";
import ShowErrMsg from "../molecule/ShowErrMsg";
import WarningAlerts from "../molecule/WarningAlerts";
import { checkForDuplicateCaptionFileNames, checkForDuplicateNames, checkForDuplicateRefIds, checkForNonS3Locations, checkForRelatedAssetsFields, checkForRequiredFields, checkForRequiredFileFormats, checkForS3Locations, checkForValidPlaceHolderValue, createCSVDataInStore, extractFilesWithJobIdAndCreatedAt, extractNotFoundFiles, extractProtocol, updateErrMsg, updateWarningMsg } from "../utility";
let urlProtocol = '';
// let notFoundFilesArr: any[] = []
let intervalId: any = ''

const AddSingleFile = () => {
  const dispatch = useDispatch();
  const location: any = useLocation();
  let loggedInUserEmail = useSelector(
    (state: any) => state.updatePineData.loggedInUserEmail
  );
  const linkedPineFolder = useSelector(
    (state: any) => state.updatePineData.linkedPineFolder
  );
  const selectedCSVFile = useSelector(
    (state: any) => state.updatePineData.singleCsvFile
  );
  const selectedCSVFileName = useSelector(
    (state: any) => state.updatePineData.singleFileName
  );
  const selectedShowChecked = useSelector(
    (state: any) => state.updatePineData.showBtnChecked
  );
  const selectedIsProcessingTab = useSelector(
    (state: any) => state.updatePineData.isProcessingTab
  );
  const selectedUploadFromStore = useSelector(
    (state: any) => state.updatePineData.selectedUploadFrom
  );
  const disabledRadioBtnsValueFromStore = useSelector(
    (state: any) => state.updatePineData.disabledRadioBtns
  );
  const rawCSVFileDataFromStore = useSelector(
    (state: any) => state.updatePineData.rawCSVFileData
  );
  let initialSelectedFile;
  let initialSelectedFileName;
  let initialShowChecked;
  let initialIsProcessing;
  let initialSelectedUploadFrom;
  let initialdisabledRadioBtnsValue;
  let initialRawCSVFileData;
  if (
    selectedCSVFile &&
    (location?.state?.previousUrl === "/pine/addMultipleFiles" || location?.state?.previousUrl === "/pine/showUploadedAssets")
  ) {
    initialSelectedFile = selectedCSVFile;
  } else {
    initialSelectedFile = "";
  }

  if (
    selectedCSVFileName &&
    (location?.state?.previousUrl === "/pine/addMultipleFiles" || location?.state?.previousUrl === "/pine/showUploadedAssets")
  ) {
    initialSelectedFileName = selectedCSVFileName;
  } else {
    initialSelectedFileName = "";
  }

  if (
    selectedShowChecked &&
    (location?.state?.previousUrl === "/pine/addMultipleFiles" || location?.state?.previousUrl === "/pine/showUploadedAssets")
  ) {
    initialShowChecked = selectedShowChecked;
  } else {
    initialShowChecked = Boolean(false);
  }

  if (
    selectedIsProcessingTab &&
    (location?.state?.previousUrl === "/pine/addMultipleFiles" || location?.state?.previousUrl === "/pine/showUploadedAssets")
  ) {
    initialIsProcessing = selectedIsProcessingTab;
  } else {
    initialIsProcessing = Boolean(false);
  }
  if (
    selectedUploadFromStore &&
    (location?.state?.previousUrl === "/pine/addMultipleFiles" ||
      location?.state?.previousUrl === "/pine/showUploadedAssets")
  ) {
    initialSelectedUploadFrom = selectedUploadFromStore;
  } else {
    initialSelectedUploadFrom = "From Local";
  }

  if (
    disabledRadioBtnsValueFromStore &&
    (location?.state?.previousUrl === "/pine/addMultipleFiles" ||
      location?.state?.previousUrl === "/pine/showUploadedAssets")
  ) {
    initialdisabledRadioBtnsValue = disabledRadioBtnsValueFromStore;
  } else {
    initialdisabledRadioBtnsValue = false;
  }

  if (rawCSVFileDataFromStore &&
    (location?.state?.previousUrl === "/pine/addMultipleFiles" ||
      location?.state?.previousUrl === "/pine/showUploadedAssets")
  ) {
    initialRawCSVFileData = rawCSVFileDataFromStore;
  } else {
    initialRawCSVFileData = [];
  }
  const [file, setFile] = useState<Blob | File | string>(initialSelectedFile);
  const [fileName, setFileName] = useState<string>(initialSelectedFileName);
  const [errMsg, setErrMsg] = useState<ErrMsgObject | undefined | any>([]);
  const [warningMsg, setWarningMsg] = useState<
    WarningMsgObject | undefined | any
  >([]);
  const [displayMsg, setDisplayMsg] = useState<string | undefined | any>([]);
  const [displayWarningMsg, setWarningDisplayMsg] = useState<
    string | undefined | any
  >([]);
  const [displayDuplicateRefIdErrMsg, setDisplayDuplicateRefIdErrMsg] = useState<
    string | undefined | any
  >([]);
  const [displayDuplicateFileNameErrMsg, setDisplayDuplicateFileNameErrMsg] = useState<
    string | undefined | any
  >([]);
  const [displayFileTypeErrMsg, setDisplayFileTypeErrMsg] = useState<
    string | undefined | any
  >([]);
  const [displayFileExtErrMsg, setDisplayFileExtErrMsg] = useState<
    string | undefined | any
  >([]);
  const [displayS3FileNamesErrMsg, setDisplayS3FileNamesErrMsg] = useState<
    string | undefined | any
  >([]);
  const [displayNonS3FileNamesErrMsg, setDisplayNonS3FileNamesErrMsg] = useState<
    string | undefined | any
  >([]);
  const [displayRefIdFilesErrMsg, setDisplayRefIdFilesErrMsg] = useState<
    string | undefined | any
  >([]);
  const [displayNameChangedFilesErrMsg, setDisplayNameChangedFilesErrMsg] = useState<
    string | undefined | any
  >([]);
  const [displayS3LocationNotFoundErrMsg, setDisplayS3LocationNotFoundErrMsg] = useState<
    string | undefined | any
  >([]);
  const [displayInvalidHttpsURLErrMsg, setDisplayInvalidHttpsURLErrMsg] = useState<
    string | undefined | any
  >([]);
  const [displayUpdateConditionErr, setDisplayUpdateConditionErr] = useState<
    string | undefined | any
  >([]);
  const [displayInvalidPlaceholderErrMsg, setInvalidPlaceholderErrMsg] = useState<
    string | undefined | any
  >([]);
  // const [displayUnderscoreRefIdErrMsg, setDisplayUnderscoreRefIdErrMsg] = useState<
  //   string | undefined | any
  // >([]);
  const [isProcessing, setIsProcessing] = useState(initialIsProcessing);
  const [showChecked, setShowChecked] = useState(initialShowChecked);
  const [currentPageNo, setCurrentPageNo] = useState(0);
  const [totalNoOfPages, setTotalNoOfPages] = useState(25);
  const [tableObjArr, setTableObjArr] = useState<any[]>(initialRawCSVFileData);
  const [searchQuery, setSearchQuery] = useState("");
  const [startValue, setStartValue] = useState(0);
  const [endValue, setEndValue] = useState(25);
  const [totalValue, setTotalValue] = useState(25);
  const [searchedDataStartPageNo, setSearchedDataStartPageNo] = useState(0);
  const [warningCountLowerLimit, setWarningCountLowerLimit] = useState(5);
  const [errCountLowerLimit, setErrCountLowerLimit] = useState<any>([]);
  const navigate = useNavigate();
  const [checkValidFile, setCheckValidFile] = useState(false);
  const [checkReqFieldsErr, setCheckReqFieldsErr] = useState(false);
  const [checkNonReqFieldsErr, setCheckNonReqFieldsErr] = useState(false);
  const [checkMetadataFileErr, setCheckMetadataFileErr] = useState(false);
  const [localMetadataRowLimitErr, setLocalMetadataRowLimitErr] = useState(false);
  const [checkMetadataFileTypesErr, setCheckMetadataFileTypesErr] = useState(false);
  const [checkMetadataFileExtErr, setCheckMetadataFileExtErr] = useState(false);
  const [fileUploadErr, setFileUploadErr] = useState(false);
  const [fileUploadErrRefId, setFileUploadErrRefId] = useState(false);
  const [fileUploadErrNameChanged, setFileUploadErrNameChanged] = useState(false);
  const [s3FilesFieldsErr, setS3FilesFieldsErr] = useState(false);
  const [s3LocationNotFound, setS3LocationNotFound] = useState(false);
  const [isHttpsValid, setIsHttpsValid] = useState(false)
  const [isUpdateConditionValid, setIsUpdateConditionValid] = useState(false)
  const [nonS3FilesFieldsErr, setNonS3FilesFieldsErr] = useState(false);
  const [radioValueselected, setRadioValueselected] = useState(initialSelectedUploadFrom);
  const [totalS3UploadAssets, setTotalS3UploadAssets] = useState(0);
  const [duplicateRefIdErr, setDuplicateRefIdErr] = useState(false);
  const [duplicateFileNameErr, setDuplicateFileNameErr] = useState(false);
  const [disableRadioBtns, setDisableRadioBtns] = useState(initialdisabledRadioBtnsValue);
  const [remoteDiffFolderNameErr, setRemoteDiffFolderNameErr] = useState(false);
  const [remoteDiffFolderNameErrMsg, setRemoteDiffFolderNameErrMsg] = useState<string | undefined | any>([]);
  const [loaderClearId, setLoaderClearId] = useState(6000);
  const [loading, setLoading] = useState(false);
  const [placeHolderErr, setPlaceholderErr] = useState(false)
  let clearId: any;

  const increamentCountHandler = () => {
    setWarningCountLowerLimit((previous: any) => {
      return previous + 5;
    });
  };

  const increamentErrCountHandler = (receivedIndex: any) => {
    let errcountArr: any = [...errCountLowerLimit];
    let countAtGivenIndex: any = errcountArr[receivedIndex];
    errcountArr[receivedIndex] = countAtGivenIndex + 5;
    setErrCountLowerLimit(errcountArr);
  }

  const handleFileDeSelect = (event: any) => {
    setShowChecked(false);
    setFile("");
    setFileName("");
    setCurrentPageNo(0);
    setStartValue(0);
    setEndValue(25);
    setIsProcessing(false);
    setTableObjArr([]);
    setCheckValidFile(false);
    setCheckReqFieldsErr(false);
    setCheckNonReqFieldsErr(false);
    setCheckMetadataFileErr(false);
    setLocalMetadataRowLimitErr(false)
    setCheckMetadataFileTypesErr(false);
    setCheckMetadataFileExtErr(false);
    setRemoteDiffFolderNameErr(false);
    setRemoteDiffFolderNameErrMsg([]);
    setDisplayFileTypeErrMsg([]);
    setDisplayFileExtErrMsg([]);
    setDisplayDuplicateFileNameErrMsg([]);
    setDisplayDuplicateRefIdErrMsg([]);
    setDisplayS3FileNamesErrMsg([]);
    setDisplayNonS3FileNamesErrMsg([]);
    setFileUploadErr(false);
    setS3FilesFieldsErr(false);
    setNonS3FilesFieldsErr(false);
    setS3LocationNotFound(false)
    setIsHttpsValid(false)
    setIsUpdateConditionValid(false)
    setWarningCountLowerLimit(5);
    setErrCountLowerLimit([]);
    setDuplicateRefIdErr(false);
    setDuplicateFileNameErr(false);
    setDisableRadioBtns(false);
    setFileUploadErrRefId(false);
    setDisplayRefIdFilesErrMsg([]);
    setFileUploadErrNameChanged(false);
    setDisplayNameChangedFilesErrMsg([]);
    setDisplayS3LocationNotFoundErrMsg([])
    setDisplayInvalidHttpsURLErrMsg([])
    setDisplayUpdateConditionErr([])
    setInvalidPlaceholderErrMsg([])
    setPlaceholderErr(false)
    dispatch(updatePineDataActions.updateSingleCsvFile({ selectedFile: "" }));
    dispatch(
      updatePineDataActions.updateSingleFileName({ singleFileName: "" })
    );
    dispatch(
      updatePineDataActions.updateCsvFileData({
        csvFileData: [],
      })
    );
    dispatch(
      updatePineDataActions.updateCsvFileDataWithStatus({
        csvFileDataWithStatus: [],
      })
    );
    dispatch(
      updatePineDataActions.updateMissingFilesData({
        missingFilesData: [],
      })
    );
  };

  const navigateToNextPage = () => {
    if (!_.isEqual(errMsg, {}) && fileUploadErr === false && fileUploadErrRefId === false && fileUploadErrNameChanged === false && (radioValueselected === "From Local" || radioValueselected === "From Zip")) {
      navigate("/pine/addMultipleFiles");
    } else if (!_.isEqual(errMsg, {}) && fileUploadErr === false && fileUploadErrRefId === false && fileUploadErrNameChanged === false && radioValueselected === "S3 to S3") {
      navigate("/pine/showUploadedAssets");
    }
  };

  const navigateToPreviousPage = () => {
    dispatch(
      updatePineDataActions.updateCsvFileData({
        csvFileData: [],
      })
    );
    navigate("/pine/addPineFolder", {
      state: {
        previousUrl: location?.pathname,
      },
    });
  };

  const currentPageIncrement = () => {
    setCurrentPageNo(currentPageNo + 1);
  };

  const currentPageDecrement = () => {
    setCurrentPageNo(currentPageNo - 1);
  };

  const searchedDataStartPageNoIncrement = () => {
    setSearchedDataStartPageNo(searchedDataStartPageNo + 1);
  };

  const searchedDataStartPageNoDecrement = () => {
    setSearchedDataStartPageNo(searchedDataStartPageNo - 1);
  };

  const getChildValues = (start: any, end: any, arrLength: any = 25) => {
    setStartValue(start);
    setEndValue(end);
    setTotalValue(arrLength);
  };

  const handleOnChange = (
    e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCheckValidFile(false);
    const target = e.target as any;
    if (
      target?.files[0]?.name.endsWith(".csv") ||
      target?.files[0]?.name.endsWith(".xlsx")
    ) {
      target.files && setFile(target.files[0]);
      target.files && setFileName(target.files[0].name);
    } else {
      setCheckValidFile(true);
    }
  };

  const handleDragOver = (
    e: React.DragEvent<HTMLTextAreaElement | HTMLInputElement | HTMLDivElement>
  ) => {
    e.preventDefault();
  };

  const handleDrop = (
    e: React.DragEvent<HTMLTextAreaElement | HTMLInputElement | HTMLDivElement>
  ) => {
    e.preventDefault();
    setCheckValidFile(false);
    if (
      e.dataTransfer.files[0]?.name.endsWith(".csv") ||
      e.dataTransfer.files[0]?.name.endsWith(".xlsx")
    ) {
      e.dataTransfer.files && setFile(e.dataTransfer.files[0]);
      e.dataTransfer.files[0] && setFileName(e.dataTransfer.files[0].name);
    } else {
      setCheckValidFile(true);
    }
  };

  const checkForDuplicateFileNames = (objData: Array<any>) => {
    let duplicatedFileNameCheck = checkForDuplicateNames(objData, 'Filename', displayDuplicateFileNameErrMsg, setDisplayDuplicateFileNameErrMsg);
    let duplicatedThumbnailFileNameCheck = checkForDuplicateNames(objData, 'Thumbnail FileName', displayDuplicateFileNameErrMsg, setDisplayDuplicateFileNameErrMsg);
    let duplicatedADFileNameCheck = checkForDuplicateNames(objData, 'AudioDescriptor FileName', displayDuplicateFileNameErrMsg, setDisplayDuplicateFileNameErrMsg);
    let duplicatednavXMLFileNameCheck = checkForDuplicateNames(objData, 'navXML FileName', displayDuplicateFileNameErrMsg, setDisplayDuplicateFileNameErrMsg);
    let duplicatedCaptionFileNameCheck = checkForDuplicateCaptionFileNames(objData, 'Caption FileNames', setDisplayDuplicateFileNameErrMsg);
    if (duplicatedFileNameCheck && duplicatedThumbnailFileNameCheck && duplicatedADFileNameCheck && duplicatednavXMLFileNameCheck && duplicatedCaptionFileNameCheck) {
      setDuplicateFileNameErr(false);
      setDisplayDuplicateFileNameErrMsg([]);
      return true;
    } else {
      return false;
    }
  };

  const checkForNextBtnDisableCondition = () => {
    if (
      file &&
      !fileUploadErr &&
      !fileUploadErrRefId &&
      !fileUploadErrNameChanged &&
      !checkValidFile &&
      !checkReqFieldsErr &&
      !checkMetadataFileErr &&
      !localMetadataRowLimitErr &&
      !checkMetadataFileTypesErr &&
      !checkMetadataFileExtErr &&
      !s3FilesFieldsErr &&
      !nonS3FilesFieldsErr &&
      !duplicateRefIdErr &&
      !duplicateFileNameErr &&
      !placeHolderErr
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleRadioValueChange = (event: any) => {
    setRadioValueselected(event.target.value)
  }

  const createAndUpdateCSVDataInStore = async (objData: any[], from: any = '') => {
    const processingObjArr: any[] = createCSVDataInStore(objData, from)
    setTableObjArr(processingObjArr);
    if (from === 'submit') {
      dispatch(
        updatePineDataActions.updateCsvFileData({
          csvFileData: processingObjArr,
        })
      );
    }
  }

  const handleFileProcessing = async () => {
    setDisableRadioBtns(true);
    let s3Condition: boolean = true;
    let nonS3Condition: boolean = true;
    const File = file as any;
    const data = await File?.arrayBuffer();
    const workBook = read(data, {type:'array'});
    const workSheet = workBook.Sheets[workBook.SheetNames[0]];
    let objData = utils.sheet_to_json(workSheet);
    //console.log(objData.length,radioValueselected,objData)
    // Convert data to UTF-8 using iconv-lite
    const utf8Data = objData.map((row: any) => {
      return Object.keys(row).reduce((acc: any, key: any) => {
        acc[key] = Iconv.decode(row[key], 'cp1252') // Replace with correct encoding
      return acc;
      }, {});
    });
    objData = utf8Data
    //console.log(utf8Data)
    if(objData.length > LOCAL_EXCEED_LIMIT && radioValueselected === "From Local"){
      setLocalMetadataRowLimitErr(true)
    }
    
    urlProtocol = extractProtocol(objData)
    dispatch(
      updatePineDataActions.setUrlProtocol({ urlProtocol: urlProtocol })
    )
    if (objData.length === 0) {
      setCheckMetadataFileErr(true);
    }
    if (radioValueselected === "S3 to S3") {
      s3Condition = checkForS3Locations(objData, setTotalS3UploadAssets, setDisplayS3FileNamesErrMsg);
      if (!s3Condition) {
        setS3FilesFieldsErr(true);
        setCheckNonReqFieldsErr(false);
      }
    } else if ((radioValueselected === "From Local" || radioValueselected === "From Zip")) {
      nonS3Condition = checkForNonS3Locations(objData, setDisplayNonS3FileNamesErrMsg);
      if (!nonS3Condition) {
        setNonS3FilesFieldsErr(true);
        setCheckNonReqFieldsErr(false);
      }
    }
    let condition: boolean = checkForRequiredFields(objData, setErrMsg);
    let condition2: boolean = checkForRequiredFileFormats(objData, setCheckMetadataFileTypesErr, setDisplayFileTypeErrMsg, setCheckMetadataFileExtErr, setDisplayFileExtErrMsg);
    let condition3: boolean = checkForRelatedAssetsFields(objData, setWarningMsg);
    let duplicateRefIdCondition: boolean = checkForDuplicateRefIds(objData, setDisplayDuplicateRefIdErrMsg, setDuplicateRefIdErr);
    let duplicateFileNamesCondition = checkForDuplicateFileNames(objData);
    let validPlaceHolderValue: boolean = checkForValidPlaceHolderValue(objData, setInvalidPlaceholderErrMsg, setPlaceholderErr)
    if (!duplicateFileNamesCondition) {
      setDuplicateFileNameErr(true);
    }
    if (s3Condition && nonS3Condition && validPlaceHolderValue && condition && condition2 && duplicateRefIdCondition && duplicateFileNamesCondition && condition3 === false) {
      setCheckNonReqFieldsErr(true);
    }
    if (s3Condition && nonS3Condition && validPlaceHolderValue && condition && condition2 && duplicateRefIdCondition && duplicateFileNamesCondition && objData.length > 0) {
      setIsProcessing(true);
      createAndUpdateCSVDataInStore(objData);
    } else if (condition === false) {
      setCheckReqFieldsErr(true);
    }
  };

  const checkValidationStatus: any = async (jobId: any, objData: any, fileSizeInKB: any) => {
    let fileTypeArr: any = []
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${apiEndpoint}/getRemoteValdationStatus?jobId=${jobId}`,
        headers: {
          "Content-Type": "application/json",
        }
      };

      let validationResponse: any = await axios(config);

      let count = validationResponse?.data?.count
      let validationStatus = validationResponse?.data?.data[count - 1]?.status
      let isError = validationResponse?.data?.data[count - 1]?.is_error
      if (validationStatus === 'BULK_UPLOAD_METADATA_VALIDATION_END') {
        if (isError === false) {
          validationResponse?.data?.data.forEach((obj: any) => {
            if (obj.status === 'BULK_UPLOAD_METADATA_VALIDATION_SUCCESS') {
              let insertedDataArray = JSON.parse(obj?.data)?.insertedData;
              let updatedDataArray = JSON.parse(obj?.data)?.updatedData;
              let jobIdAndCreatedAtArr: any[] = [];
              if ((insertedDataArray && insertedDataArray.length > 0) || updatedDataArray?.length > 0) {
                insertedDataArray.forEach((item: any) => {
                  jobIdAndCreatedAtArr.push({
                    job_id: item.job_id,
                    created_at: item.created_at,
                  });
                });
                updatedDataArray.forEach((item: any) => {
                  jobIdAndCreatedAtArr.push({
                    job_id: item.job_id,
                    created_at: item.created_at,
                  });
                });
              }
              dispatch(
                updatePineDataActions.updateJobIdCreatedAtArray({
                  jobIdCreatedAtArray: jobIdAndCreatedAtArr,
                })
              )
            }
          })
          clearInterval(intervalId)
          setLoading(false);
          dispatch(
            updatePineDataActions.updateUploadReportFlag({
              uploadReportFlag: true,
            })
          );
          rawCSVFileDataFromStore?.map((item: any) => {
            if (item?.fileType && item.fileType !== '') {
              fileTypeArr.push(item.fileType);
            } else {
              fileTypeArr.push('Other');
            }
          })
          // GA code to track remote upload
          const remoteUpload = {
            event: 'remote-upload',
            uploadType: urlProtocol === 's3' ? 'S3_upload' : 'HTTPS_upload',
            totalFiles: rawCSVFileDataFromStore?.length,
            fileTypes: fileTypeArr,
            totalRows: objData?.length,
            metadataUploadSize: `${fileSizeInKB} KB`
          }
          triggerCustomEventsGTM(remoteUpload)
          navigateToNextPage();
        } else {
          setLoading(false)
          setFileUploadErr(true);
          validationResponse?.data?.data.slice(1, -1).forEach((obj: any) => {
            let errorObject = JSON.parse(obj?.errors)
            clearInterval(intervalId)
            checkAndRaiseErrOfRemoteUpload(errorObject)
          });
        }
      }
    } catch (error) {
      console.log("Error ocured while uploading CSV file", error);
      setFileUploadErr(true);
      setLoading(false);
      checkAndRaiseErr(error);
    }
  }

  const checkIsUpload = () => {
    if (window.location.href.split('/').pop() === 'showUploadedAssets')
      return true;
    else
      return false;
  }

  const checkAndRaiseErrOfRemoteUpload = (errorObject: any) => {
    if (errorObject?.error && errorObject?.error?.length > 0) {
      let duplicateRefIdArr = errorObject?.error;
      let dummyArray1: any = [];
      let dummyArray2: any = [];
      let dummyArray3: any = [];
      duplicateRefIdArr?.forEach((item: any) => {
        if (item?.error_code === 1) {
          let str: any = `${item?.error_message}`;
          dummyArray1.push(str);
          setFileUploadErr(false);
          setFileUploadErrRefId(true);
          setDisplayRefIdFilesErrMsg(dummyArray1);
        }
        if (item?.error_code === 2) {
          let str: any = `${item?.referenceId} : ${item?.thumbnailFilename ? item?.thumbnailFilename : item?.navXMLFileName ? item?.navXMLFileName : item?.audioDescriptorFilename}`;
          dummyArray2.push(str);
          setFileUploadErr(false);
          setFileUploadErrNameChanged(true);
          setDisplayNameChangedFilesErrMsg(dummyArray2);
        }
        if (item?.error_code === 89) {
          let str: any = `${item?.error_message}`;
          dummyArray3.push(str);
          setFileUploadErr(false);
          setIsUpdateConditionValid(true)
          setDisplayUpdateConditionErr(dummyArray3);
        }
        if (errorObject?.error_code === 15) {
          setFileUploadErr(false);
          setS3LocationNotFound(true)
          let returnedArr = extractNotFoundFiles(errorObject?.error)
          setDisplayS3LocationNotFoundErrMsg(returnedArr);
        }
        if (errorObject?.error_code === 16) {
          setFileUploadErr(false);
          setIsHttpsValid(true)
          let returnedArr = extractNotFoundFiles(errorObject?.error)
          setDisplayInvalidHttpsURLErrMsg(returnedArr);
        }
        if (errorObject?.error_code === 51) {
          setFileUploadErr(false);
          setRemoteDiffFolderNameErr(true);
          setRemoteDiffFolderNameErrMsg(errorObject?.error?.map((item: any) => {
            return item?.error;
          }));
        }
        else if (errorObject?.response?.status === 403 && errorObject?.error_code === 51) {
          setFileUploadErr(false);
          setRemoteDiffFolderNameErr(true);
          setRemoteDiffFolderNameErrMsg(errorObject?.error?.map((item: any) => {
            return item?.error;
          }))
        }
      });
    }
  }

  const checkAndRaiseErr = (error: any) => {
    if (error?.response?.status === 403 && error?.response?.data?.error?.length > 0) {
      let duplicateRefIdArr = error?.response?.data?.error;
      let dummyArray1: any = [];
      let dummyArray2: any = [];
      let dummyArray3: any = [];
      duplicateRefIdArr?.forEach((item: any) => {
        if (item?.error_code === 1) {
          let str: any = `${item?.error_message}`;
          dummyArray1.push(str);
          setFileUploadErr(false);
          setFileUploadErrRefId(true);
          setDisplayRefIdFilesErrMsg(dummyArray1);
        }
        if (item?.error_code === 2) {
          let str: any = `${item?.referenceId} : ${item?.thumbnailFilename ? item?.thumbnailFilename : item?.navXMLFileName ? item?.navXMLFileName : item?.audioDescriptorFilename}`;
          dummyArray2.push(str);
          setFileUploadErr(false);
          setFileUploadErrNameChanged(true);
          setDisplayNameChangedFilesErrMsg(dummyArray2);
        }
        if (item?.error_code === 89) {
          let str: any = `${item?.error_message}`;
          dummyArray3.push(str);
          setFileUploadErr(false);
          setIsUpdateConditionValid(true)
          setDisplayUpdateConditionErr(dummyArray3);
        }
        if (error?.response?.data?.error_code === 15) {
          setFileUploadErr(false);
          setS3LocationNotFound(true)
          let returnedArr = extractNotFoundFiles(error?.response?.data?.error)
          setDisplayS3LocationNotFoundErrMsg(returnedArr);
        }
        if (error?.response?.data?.error_code === 16) {
          setFileUploadErr(false);
          setIsHttpsValid(true)
          let returnedArr = extractNotFoundFiles(error?.response?.data?.error)
          setDisplayInvalidHttpsURLErrMsg(returnedArr);
        }
        if (error?.response?.data?.error_code === 51) {
          setFileUploadErr(false);
          setRemoteDiffFolderNameErr(true);
          setRemoteDiffFolderNameErrMsg(error?.response?.data?.error?.map((item: any) => {
            return item?.error;
          }));
        }
        else if (error?.response?.status === 403 && error?.response?.data?.error_code === 51) {
          setFileUploadErr(false);
          setRemoteDiffFolderNameErr(true);
          setRemoteDiffFolderNameErrMsg(error?.response?.data?.error?.map((item: any) => {
            return item?.error;
          }))
        }
      });
    }
  }


  const handleSubmit = async () => {
    let s3Condition: boolean = true;
    let nonS3Condition: boolean = true;
    setLoading(true);
    setDisableRadioBtns(true);
    dispatch(
      updatePineDataActions.updateSingleFileName({ singleFileName: fileName })
    );
    dispatch(
      updatePineDataActions.updateNotFoundFilesErr({
        notFoundFilesArr: [],
      })
    );
    const File = file as any;
    const fileSizeInKB = File?.size / 1024
    const data = await File.arrayBuffer();
    const workBook = read(data, {type:'array'});
    const workSheet = workBook.Sheets[workBook.SheetNames[0]];
    let objData = utils.sheet_to_json(workSheet);

    // Convert data to UTF-8 using iconv-lite
    const utf8Data = objData.map((row: any) => {
      return Object.keys(row).reduce((acc: any, key: any) => {
        acc[key] = Iconv.decode(row[key], 'cp1252') // Replace with correct encoding
      return acc;
      }, {});
    });
    objData = utf8Data
    
    if(objData.length > LOCAL_EXCEED_LIMIT && radioValueselected === "From Local"){
      setLocalMetadataRowLimitErr(true);
      setLoading(false);
      return;
    }

    urlProtocol = extractProtocol(objData)
    dispatch(
      updatePineDataActions.setUrlProtocol({ urlProtocol: urlProtocol })
    )
    if (objData.length === 0) {
      setCheckMetadataFileErr(true);
    }
    if (radioValueselected === "S3 to S3") {
      s3Condition = checkForS3Locations(objData, setTotalS3UploadAssets, setDisplayS3FileNamesErrMsg);
      if (!s3Condition) {
        setS3FilesFieldsErr(true);
        setCheckNonReqFieldsErr(false);
      }
    } else if ((radioValueselected === "From Local" || radioValueselected === "From Zip")) {
      nonS3Condition = checkForNonS3Locations(objData, setDisplayNonS3FileNamesErrMsg);
      if (!nonS3Condition) {
        setNonS3FilesFieldsErr(true);
        setCheckNonReqFieldsErr(false);
      }
    }
    let condition: boolean = checkForRequiredFields(objData, setErrMsg);
    let condition2: boolean = checkForRequiredFileFormats(objData, setCheckMetadataFileTypesErr, setDisplayFileTypeErrMsg, setCheckMetadataFileExtErr, setDisplayFileExtErrMsg);
    let condition3: boolean = checkForRelatedAssetsFields(objData, setWarningMsg);
    let duplicateRefIdCondition: boolean = checkForDuplicateRefIds(objData, setDisplayDuplicateRefIdErrMsg, setDuplicateRefIdErr);
    let duplicateFileNamesCondition = checkForDuplicateFileNames(objData);
    let validPlaceHolderValue: boolean = checkForValidPlaceHolderValue(objData, setInvalidPlaceholderErrMsg, setPlaceholderErr)
    if (!condition) {
      setCheckReqFieldsErr(true);
    }
    if (!duplicateFileNamesCondition) {
      setDuplicateFileNameErr(true);
    }
    if (s3Condition && nonS3Condition && validPlaceHolderValue && condition && condition2 && duplicateRefIdCondition && duplicateFileNamesCondition && condition3 === false) {
      setCheckNonReqFieldsErr(true);
    }
    const partiallyProcessedJSON = await structuredJson2(
      objData,
      loggedInUserEmail,
      fileName
    );
    await createAndUpdateCSVDataInStore(partiallyProcessedJSON, 'submit');
    let extractedFileName = fileName.split(".")[0];
    let finalJson = {
      csvdata: partiallyProcessedJSON,
      foldername: linkedPineFolder,
      filename: extractedFileName,
      isUpload: checkIsUpload(),
      useremail: loggedInUserEmail,
      uploadType: selectedUploadFromStore
    };
    let finalJson2 = {
      csvdata: partiallyProcessedJSON,
      foldername: linkedPineFolder,
      metaDataFilename: extractedFileName,
      useremail: loggedInUserEmail
    };
    if (finalJson.csvdata.length === 0) {
      setCheckMetadataFileErr(true);
    }
    if (
      nonS3Condition &&
      s3Condition &&
      condition &&
      condition2 &&
      duplicateRefIdCondition &&
      duplicateFileNamesCondition &&
      validPlaceHolderValue &&
      (radioValueselected === "From Local" || radioValueselected === "From Zip")
    ) {
      let config = {
        method: "post",
        url: `${apiEndpoint}/saveUploadCsvToS3`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(finalJson),
      };

      axios(config)
        .then(function (response) {
          let alreadyUploaded: Boolean = false
          let filenameArr: any = []
          let finalJsonObj = { ...JSON.parse(JSON.stringify(finalJson)) }
          let combinedDataArr: any = []
          let extractedInsertedFileWithJobIdAndCreatedArray: any = []
          let extractedUpdateFileWithJobIdAndCreatedArray: any = []
          if (response?.data?.insertedData.length > 0) {
            extractedInsertedFileWithJobIdAndCreatedArray = extractFilesWithJobIdAndCreatedAt(response?.data?.insertedData, alreadyUploaded)
            response?.data?.insertedData.map((item: any) => {
              if (item.placeholder === 'N') {
                let obj: any = {}
                obj.filename = item.filename
                obj.job_id = item.job_id
                obj.created_at = item.created_at
                obj.comcast_version = item.comcast_version
                filenameArr.push(obj)
              }
            })
          }
          if (response?.data?.uploadType === 'From Zip') {
            dispatch(
              updatePineDataActions.updateTrackingJobId({
                trackingJobId: response?.data?.trackingJobId,
              })
            );
          }
          if (response?.data?.updatedData.length > 0) {
            extractedUpdateFileWithJobIdAndCreatedArray = extractFilesWithJobIdAndCreatedAt(response?.data?.updatedData, !alreadyUploaded)
          }
          combinedDataArr = [...response?.data?.insertedData, ...response?.data?.updatedData]
          combinedDataArr?.map((item: any) => {
            let match = finalJsonObj?.csvdata?.find((item2: any) => item2.filename === item.filename);
            if (match) {
              match.job_id = item.job_id
              match.created_at = item.created_at
            }
          })
          dispatch(
            updatePineDataActions.updateFinalJson({
              finalJson: finalJsonObj,
            })
          );
          dispatch(
            updatePineDataActions.updateAllFilesWithJobIdCreatedAtArray({
              allFilesWithJobIdCreatedAtArray: [...extractedInsertedFileWithJobIdAndCreatedArray, ...extractedUpdateFileWithJobIdAndCreatedArray],
            })
          );
          dispatch(
            updatePineDataActions.updatefilenameWithJobIdCreatedAtArray({
              filenameWithJobIdCreatedAtArray: filenameArr,
            })
          );
          dispatch(updatePineDataActions.setFileSizeInKB(`${fileSizeInKB} KB`))
          setFileUploadErr(false);
          setLoading(false)
          navigateToNextPage();
        })
        .catch(function (error) {
          console.log("err : ", error);
          setFileUploadErr(true);
          setLoading(false)
          checkAndRaiseErr(error);
        });
    } else if (
      nonS3Condition &&
      s3Condition &&
      condition &&
      condition2 &&
      duplicateRefIdCondition &&
      duplicateFileNamesCondition &&
      radioValueselected === "S3 to S3"
    ) {
      try {
        let config2 = {
          method: "post",
          url: urlProtocol === 's3' ? `${apiEndpoint}/s3ToS3Transfer` : `${apiEndpoint}/transferUrlToS3`,
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(finalJson2),
        };

        let api1Response: any = await axios(config2)
        dispatch(
          updatePineDataActions.updateJobId({
            csvJobId: api1Response?.data?.job_id,
          })
        );
        intervalId = setInterval(async () => {
          await checkValidationStatus(api1Response?.data?.job_id, objData, fileSizeInKB);
        }, 5000);
      }
      catch (error) {
        console.log("Error ocured while uploading CSV file", error);
        setFileUploadErr(true);
        setLoading(false);
        checkAndRaiseErr(error);
      }
    } else setLoading(false);
  };

  const checkForTheErrAndReturnTitle = () => {
    let errTitleArr: any[] = [];
    if (checkMetadataFileTypesErr) {
      errTitleArr.push(FILE_TYPE_ERR_TITLE)
    }
    if (checkMetadataFileExtErr) {
      errTitleArr.push(MISSING_EXTENSION_ERR_TITLE)
    }
    if (duplicateRefIdErr) {
      errTitleArr.push(DUPLICATE_REF_ID_ERR_TITLE)
    }
    if (duplicateFileNameErr) {
      errTitleArr.push(DUPLICATE_FILENAME_ERR_TITLE)
    }
    if (s3FilesFieldsErr) {
      errTitleArr.push("Below fields missing " + urlProtocol + " location.")
    }
    if (nonS3FilesFieldsErr) {
      errTitleArr.push("Below fields contains " + urlProtocol + " location. Please give normal file names.")
    }
    if (checkReqFieldsErr) {
      errTitleArr.push(REQUIRED_FIELD_ERR_TITLE)
    }
    if (checkMetadataFileErr) {
      errTitleArr.push(METADATA_FILE_ERR_TITLE)
    }
    if (localMetadataRowLimitErr) {
      errTitleArr.push(LOCAL_EXCEEDED_ROWS)
    }
    if (fileUploadErr) {
      errTitleArr.push(METADATA_UPLOAD_ERR_TITLE)
    }
    if (fileUploadErrRefId) {
      errTitleArr.push(ALREADY_EXIST_REF_ID_ERR_TITLE)
    }
    if (fileUploadErrNameChanged) {
      errTitleArr.push(FILE_UPLOAD_NAME_CHANGE_TITLE)
    }
    if (s3LocationNotFound) {
      errTitleArr.push(S3_LOCATION_NOTFOUND_ERR_TITLE)
    }
    if (isHttpsValid) {
      errTitleArr.push(HTTPS_LOCATION_NOTFOUND_ERR_TITLE)
    }
    if (isUpdateConditionValid) {
      errTitleArr.push(INVALID_FILE_UPDATE_ERR_TITLE)
    }
    if (remoteDiffFolderNameErr) {
      errTitleArr.push(DUPLICATE_FILE_ERR_TITLE)
    }
    if (placeHolderErr) {
      errTitleArr.push(INVALID_PLACEHOLDER_ERR_TITLE)
    }
    let loopLength: any = errTitleArr?.length;
    if (errCountLowerLimit?.length < loopLength) {
      for (let i = 1; i <= loopLength; i++) {
        setErrCountLowerLimit((previous: any) => {
          return [...previous, 5];
        });
      }
    }
    return errTitleArr;
  }

  const checkForTheErrAndReturnErrArr = () => {
    let errArr: any[] = [];
    if (checkMetadataFileTypesErr) {
      errArr.push(displayFileTypeErrMsg)
    }
    if (checkMetadataFileExtErr) {
      errArr.push(displayFileExtErrMsg)
    }
    if (duplicateRefIdErr) {
      errArr.push(displayDuplicateRefIdErrMsg)
    }
    if (duplicateFileNameErr) {
      errArr.push(displayDuplicateFileNameErrMsg)
    }
    if (s3FilesFieldsErr) {
      errArr.push(displayS3FileNamesErrMsg)
    }
    if (nonS3FilesFieldsErr) {
      errArr.push(displayNonS3FileNamesErrMsg)
    }
    if (fileUploadErr) {
      errArr.push("")
    }
    if (checkMetadataFileErr) {
      errArr.push("")
    }
    if (localMetadataRowLimitErr) {
      errArr.push("")
    }
    if (checkReqFieldsErr) {
      errArr.push(displayMsg)
    }
    if (fileUploadErrRefId) {
      errArr.push(displayRefIdFilesErrMsg)
    }
    if (fileUploadErrNameChanged) {
      errArr.push(displayNameChangedFilesErrMsg)
    }
    if (remoteDiffFolderNameErr) {
      errArr.push(remoteDiffFolderNameErrMsg)
    }
    if (s3LocationNotFound) {
      errArr.push(displayS3LocationNotFoundErrMsg)
    }
    if (isHttpsValid) {
      errArr.push(displayInvalidHttpsURLErrMsg)
    }
    if (isUpdateConditionValid) {
      errArr.push(displayUpdateConditionErr)
    }
    if (placeHolderErr) {
      errArr.push(displayInvalidPlaceholderErrMsg)
    }
    return errArr;
  }

  const checkForTheErrAndReturnUniqueStr = () => {
    let errUniqueStrArr: any[] = [];
    if (checkMetadataFileTypesErr) {
      errUniqueStrArr.push("checkFileTypesErr")
    }
    if (checkMetadataFileExtErr) {
      errUniqueStrArr.push("")
    }
    if (duplicateRefIdErr) {
      errUniqueStrArr.push("")
    }
    if (duplicateFileNameErr) {
      errUniqueStrArr.push("")
    }
    if (s3FilesFieldsErr) {
      errUniqueStrArr.push("")
    }
    if (nonS3FilesFieldsErr) {
      errUniqueStrArr.push("")
    }
    if (fileUploadErr) {
      errUniqueStrArr.push("")
    }
    if (checkMetadataFileErr) {
      errUniqueStrArr.push("")
    }
    if (localMetadataRowLimitErr) {
      errUniqueStrArr.push("")
    }
    if (checkReqFieldsErr) {
      errUniqueStrArr.push("checkReqFieldsErr")
    }
    if (fileUploadErrRefId) {
      errUniqueStrArr.push("")
    }
    if (fileUploadErrNameChanged) {
      errUniqueStrArr.push("")
    }
    if (remoteDiffFolderNameErr) {
      errUniqueStrArr.push("")
    }
    if (s3LocationNotFound) {
      errUniqueStrArr.push("")
    }
    if (isHttpsValid) {
      errUniqueStrArr.push("")
    }
    if (isUpdateConditionValid) {
      errUniqueStrArr.push("")
    }
    if (placeHolderErr) {
      errUniqueStrArr.push("")
    }
    return errUniqueStrArr;
  }

  useEffect(() => {
    const getClearID = async () => {
      const clearId = await getClearIdFromDB();
      setLoaderClearId(clearId);
    };

    getClearID();
  }, []);

  useEffect(() => {
    if (linkedPineFolder === "") {
      navigate("/pine");
    }
  }, [])

  useEffect(() => {
    dispatch(
      updatePineDataActions.updateRawCSVFileData({
        rawCSVFileData: tableObjArr,
      })
    );
  }, [tableObjArr]);

  useEffect(() => {
    dispatch(
      updatePineDataActions.updateTotalS3Files({
        totalS3Files: totalS3UploadAssets,
      })
    );
  }, [totalS3UploadAssets]);

  useEffect(() => {
    dispatch(
      updatePineDataActions.updateSelectedUploadFrom({
        selectedUploadFrom: radioValueselected,
      })
    );
  }, [radioValueselected]);

  useEffect(() => {
    dispatch(
      updatePineDataActions.setDisabledRadioBtns({
        disabledRadioBtns: disableRadioBtns,
      })
    );

  }, [disableRadioBtns]);

  useEffect(() => {
    dispatch(updatePineDataActions.updateSingleCsvFile({ selectedFile: file }));
  }, [file]);

  useEffect(() => {
    dispatch(
      updatePineDataActions.updateshowBtnChecked({
        showBtnChecked: showChecked,
      })
    );
  }, [showChecked]);

  useEffect(() => {
    dispatch(
      updatePineDataActions.updateisProcessingTab({
        isProcessingTab: isProcessing,
      })
    );
  }, [isProcessing]);

  useEffect(() => {
    let totalArrItem = tableObjArr.length;
    let itemsPerPage = 8;
    let noOfPages = Math.ceil(totalArrItem / itemsPerPage);
    setTotalNoOfPages(noOfPages);
  }, [tableObjArr]);

  useEffect(() => {
    updateErrMsg(errMsg, setDisplayMsg);
  }, [errMsg]);

  useEffect(() => {
    updateWarningMsg(warningMsg, setWarningDisplayMsg);
  }, [warningMsg]);

  return (
    <>
      {loading && (
        <Box className="loader-overlay">
          <CircularProgress className="loader" size={100} disableShrink />
        </Box>
      )}
      <Grid container sx={{ width: "500px", marginTop: "20px" }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
          paddingY={1}
        >
          <PageText />
          <ButtonsWithGrid
            checkForNextBtnDisableCondition={checkForNextBtnDisableCondition}
            navigateToPreviousPage={navigateToPreviousPage}
            handleSubmit={handleSubmit}
          />
        </Grid>
        <CustomDivider />
        <UploadIcon />
        <Grid
          item
          xs={12}
          marginBottom={isProcessing ? "0px" : "50px"}
          sx={{
            borderRadius: "10px",
            marginTop: "10px",
            padding: "10px",
            backgroundColor: "white",
          }}
        >
          <FormControlGrid
            handleRadioValueChange={handleRadioValueChange}
            radioValueselected={radioValueselected}
            disableRadioBtns={disableRadioBtns}
          />
          <SubTexts />
          {checkNonReqFieldsErr &&
            (WarningAlerts(
              MISSING_NON_REQ_FIELDS_WARN_TITLE,
              displayWarningMsg,
              warningCountLowerLimit,
              increamentCountHandler,
            ))
          }
          {(s3LocationNotFound || isHttpsValid || isUpdateConditionValid || checkMetadataFileTypesErr || checkMetadataFileExtErr || duplicateRefIdErr
            || duplicateFileNameErr || s3FilesFieldsErr || nonS3FilesFieldsErr || checkReqFieldsErr || checkMetadataFileErr || localMetadataRowLimitErr
            || fileUploadErr || fileUploadErrRefId || fileUploadErrNameChanged || remoteDiffFolderNameErr || placeHolderErr) &&
            (ShowErrMsg(
              checkForTheErrAndReturnTitle(),
              checkForTheErrAndReturnErrArr(),
              checkForTheErrAndReturnUniqueStr(),
              errCountLowerLimit,
              increamentErrCountHandler,
            ))
          }
          <DragandDropWithErrorMsg
            fileName={fileName}
            handleFileDeSelect={handleFileDeSelect}
            showChecked={showChecked}
            checkValidFile={checkValidFile}
            checkReqFieldsErr={checkReqFieldsErr}
            checkMetadataFileErr={checkMetadataFileErr}
            checkMetadataFileTypesErr={checkMetadataFileTypesErr}
            checkMetadataFileExtErr={checkMetadataFileExtErr}
            s3FilesFieldsErr={s3FilesFieldsErr}
            nonS3FilesFieldsErr={nonS3FilesFieldsErr}
            duplicateRefIdErr={duplicateRefIdErr}
            placeHolderErr={placeHolderErr}
            duplicateFileNameErr={duplicateFileNameErr}
            handleFileProcessing={handleFileProcessing}
            setShowChecked={setShowChecked}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            handleOnChange={handleOnChange}
            radioValueselected={radioValueselected}
            selectedCSVFile={selectedCSVFile}
          />
        </Grid>
        {isProcessing && (
          <Grid
            item
            marginBottom="50px"
            xs={12}
            sx={{
              borderRadius: "10px",
              marginTop: "2%",
              padding: "2%",
              backgroundColor: "white",
            }}
          >
            <IdentifiedFilesText />
            <Grid item container direction="row" sx={{ fontSize: "12px" }}>
              We've processed your file and identified{" "}
              {searchQuery ? totalValue : tableObjArr.length} assets for
              upload in the next step.
            </Grid>
            <CustomToogleWithTextField
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              startValue={startValue}
              endValue={endValue}
              totalValue={totalValue}
              tableObjArr={tableObjArr}
              searchedDataStartPageNoDecrement={searchedDataStartPageNoDecrement}
              searchedDataStartPageNoIncrement={searchedDataStartPageNoIncrement}
              currentPageDecrement={currentPageDecrement}
              currentPageIncrement={currentPageIncrement}
            />
            <CustomizedTables
              tableObjArr={tableObjArr}
              currentPageNo={currentPageNo}
              totalNoOfPages={totalNoOfPages}
              searchQuery={searchQuery}
              handleFileProcessing={handleFileProcessing}
              getChildValues={getChildValues}
              searchedDataStartPageNo={searchedDataStartPageNo}
            />
            <CustomToggle
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              startValue={startValue}
              endValue={endValue}
              totalValue={totalValue}
              tableObjArr={tableObjArr}
              searchedDataStartPageNoDecrement={searchedDataStartPageNoDecrement}
              searchedDataStartPageNoIncrement={searchedDataStartPageNoIncrement}
              currentPageDecrement={currentPageDecrement}
              currentPageIncrement={currentPageIncrement}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AddSingleFile;
